<template>
    <card-table-search title="Liste de vos emprunts" :items="emprunts" :fields="fields" :actions="actions"
                       :custom-rendered="['jeux', 'livres', 'valide']">
        <template #title>
            Liste de vos emprunts
            <b-button pill variant="success" size="sm" class="ml-2" v-b-tooltip.hover="'Nouvelle demande'"
                      @click="$router.push({name: 'creation_emprunt'})">
                <font-awesome-icon icon="plus"/>
            </b-button>
        </template>
        <template #jdrs="{ row }">
            <div class="large-column-table" v-b-popover.hover.left="row.value">
                {{ row.value }}
            </div>
        </template>
        <template #jeux="{ row }">
            <div class="large-column-table" v-b-popover.hover.left="row.value">
                {{ row.value }}
            </div>
        </template>
        <template #livres="{ row }">
            <div class="large-column-table" v-b-popover.hover.left="row.value">
                {{ row.value }}
            </div>
        </template>
        <template #valide="slotProps">
            <b-badge :variant="getBadgeColorStatut(slotProps.row.item)">
                {{ getTextStatut(slotProps.row.item) }}
            </b-badge>
        </template>
    </card-table-search>
</template>

<script>
    const CardTableSearch = () => import('@/components/CardTableSearch');

    import {apiPath}     from '@/util/http';
    import demande       from '@/util/demande';
    import alert         from '@/util/alert';
    import empruntsMixin from '@/mixin/empruntsMixin';

    export default {
        name: "EmpruntsDashboard",
        components: {CardTableSearch},
        mixins: [empruntsMixin],
        data() {
            return {
                emprunts: [],
                fields: [
                    {
                        key: 'dateDebut',
                        label: 'Dates',
                        sortable: true,
                        formatter: (value, key, item) => demande.formatDates(item)
                    },
                    {
                        key: 'valide',
                        label: 'Statut',
                        sortable: true,
                        formatter: (value, key, item) => demande.getTextStatut(item)
                    },
                    {
                        key: 'jdrs',
                        label: 'JdR',
                        formatter: value => value.map(({nom}) => nom).join(', ')
                    },
                    {
                        key: 'jeux',
                        label: 'Jeux',
                        formatter: value => value.map(({nom}) => nom).join(', ')
                    },
                    {
                        key: 'livres',
                        label: 'Livres',
                        formatter: value => value.map(({nom}) => nom).join(', ')
                    },
                    {
                        key: 'actions',
                        label: 'Actions'
                    }
                ],
                actions: [
                    {
                        key: 'action_display',
                        color: () => 'secondary',
                        icon: 'eye',
                        tooltip: 'Détail',
                        handler: ({item}) => this.displayEmpruntModal(item)
                    },
                    {
                        key: 'action_edit',
                        color: () => 'info',
                        icon: 'pen',
                        tooltip: 'Éditer',
                        handler: ({item}) => this.editEmpruntModal(item),
                        display: ({item}) => item.valide === null
                    },
                    {
                        key: 'action_delete',
                        color: () => 'danger',
                        icon: 'trash',
                        tooltip: 'Supprimer',
                        handler: ({item}) => this.deleteEmprunt(item),
                        display: ({item}) => item.valide === null
                    }
                ]
            };
        },
        methods: {
            ...demande,
            loadData() {
                alert.loading();
                this.axios.get(apiPath('list_user_emprunts'))
                    .then(response => this.emprunts = this.addRowVariant(demande.momentDates(response.data)))
                    .catch(() => this.$toaster.error('Impossible de récupérer la liste de vos emprunts'))
                    .finally(alert.stopLoading);
            }
        },
        mounted() {
            this.loadData();
        }
    }
</script>

<style scoped>
    .large-column-table {
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 200px;
        overflow: hidden;
    }
</style>
